import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion'; // Import Framer Motion

const Header = () => {
  const darkBlack = '#1a1a1a';
  const clearWhite = '#1a1a1a';
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState('Homepage');
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Mobile menu state

  useEffect(() => {
    const path = location.pathname;
    if (path === '/') {
      setActiveMenu('Homepage');
    } else if (path === '/explore-products') {
      setActiveMenu('Explore Products');
    } else if (path === '/trending-products') {
      setActiveMenu('Trending Products');
    } else if (path === '/discord') {
      setActiveMenu('Discord');
    }
  }, [location.pathname]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the mobile menu
  };

  return (
    <header className="bg-transparent border-b border-gray-700"> {/* Darker border for Dark Mode */}
      <div className="max-w-[1308px] mx-auto flex justify-between items-center py-4 px-6">
        {/* Logo */}
        <div className="flex items-center">
          <Link to="/" className="flex items-center">
            <div
              className="flex items-center justify-center w-10 h-10 rounded-[8px]"
              style={{ backgroundColor: clearWhite }}
            >
              <span className="text-white text-xl font-bold">V</span>
            </div>
            <span className="ml-3 text-xl font-bold text-gray-200">Vontures</span> {/* Textfarbe angepasst für Dark Mode */}
          </Link>
        </div>

        {/* Desktop Navigation Links */}
        <nav className="hidden lg:flex">
          <ul className="flex space-x-8 text-[15px] font-medium text-gray-400">
            <li>
              <Link
                to="/"
                className={`px-3 py-1.5 rounded-lg transition ${
                  activeMenu === 'Homepage' ? 'bg-[#1a1a1a] text-white' : 'hover:text-gray-200'
                }`}
              >
                Homepage
              </Link>
            </li>
            <li>
              <Link
                to="/explore-products"
                className={`px-4 py-2 rounded-lg transition ${
                  activeMenu === 'Explore Products' ? 'bg-[#1a1a1a] text-white' : 'hover:text-gray-200'
                }`}
              >
                Explore Products
              </Link>
            </li>
            <li>
              <Link
                to="/trending-products"
                className={`px-4 py-2 rounded-lg transition ${
                  activeMenu === 'Trending Products' ? 'bg-[#1a1a1a] text-white' : 'hover:text-gray-200'
                }`}
              >
                Trending Products
              </Link>
            </li>
            <li>
              <Link
                to="/discord"
                className={`px-4 py-2 rounded-lg transition ${
                  activeMenu === 'Discord' ? 'bg-[#1a1a1a] text-white' : 'hover:text-gray-200'
                }`}
              >
                Discord
              </Link>
            </li>
          </ul>
        </nav>

        {/* Discord Button for Desktop */}
        <div className="hidden lg:block">
          <button
            className="border rounded-[8px] py-2 px-4 hover:bg-opacity-20 transition"
            style={{
              borderColor: darkBlack,
              color: 'white', // Button text in white for Dark Mode
              backgroundColor: darkBlack,
            }}
            onClick={() => window.open('https://discord.gg/wP8fxCqcfT', '_blank')}
          >
            Discord
          </button>
        </div>

        {/* Mobile Menu Button */}
        <div className="lg:hidden">
          <button className="text-gray-400 focus:outline-none" onClick={toggleMenu}>
            <motion.svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              animate={{ rotate: isMenuOpen ? 90 : 0 }} // Rotate burger icon
              transition={{ duration: 0.3 }}
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </motion.svg>
          </button>
        </div>
      </div>

      {/* Mobile Navigation */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            className="lg:hidden bg-[#1a1a1a] border-t border-gray-700" // Dark background for mobile menu
            initial={{ height: 0, opacity: 0 }} // Initial state
            animate={{ height: "auto", opacity: 1 }} // Animate to expanded state
            exit={{ height: 0, opacity: 0 }} // Animate on exit
            transition={{ duration: 0.4 }}
          >
            <ul className="flex flex-col items-center text-[15px] font-medium text-gray-400 space-y-4 py-4">
              <motion.li
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.1 }}
              >
                <Link
                  to="/"
                  onClick={toggleMenu}
                  className={`block px-4 py-2 rounded-lg transition ${
                    activeMenu === 'Homepage' ? 'bg-gray-800 text-white' : 'hover:text-white'
                  }`}
                >
                  Homepage
                </Link>
              </motion.li>
              <motion.li
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                <Link
                  to="/explore-products"
                  onClick={toggleMenu}
                  className={`block px-4 py-2 rounded-lg transition ${
                    activeMenu === 'Explore Products' ? 'bg-gray-800 text-white' : 'hover:text-white'
                  }`}
                >
                  Explore Products
                </Link>
              </motion.li>
              <motion.li
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                <Link
                  to="/trending-products"
                  onClick={toggleMenu}
                  className={`block px-8 py-2 rounded-lg transition ${
                    activeMenu === 'Trending Products' ? 'bg-gray-800 text-white' : 'hover:text-white'
                  }`}
                >
                  Trending Products
                </Link>
              </motion.li>
              <motion.li
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                <Link
                  to="/discord"
                  onClick={toggleMenu}
                  className={`block px-4 py-2 rounded-lg transition ${
                    activeMenu === 'Discord' ? 'bg-gray-800 text-white' : 'hover:text-white'
                  }`}
                >
                  Discord
                </Link>
              </motion.li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;
