import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Homepage = () => {
  const darkBackground = '#121212'; // Haupt-Hintergrundfarbe für Dark Mode
  const cardBackground = '#1a1a1a'; // Dunkles Grau für Kartenelemente
  const primaryText = '#f0f0f0'; // Helles Grau für den Haupttext
  const secondaryText = '#a1a1a1'; // Sekundärtext
  const buttonColor = '#2c2c2c'; // Grau für die Buttons
  const hoverColor = '#3a3a3a'; // Helleres Grau für Hover-Effekte

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: 'easeInOut', staggerChildren: 0.2 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: 'easeInOut' } },
  };

  return (
    <motion.div
      className="w-full px-[5%] box-border"
      style={{ backgroundColor: darkBackground }}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="min-h-screen flex flex-col items-center pt-20 max-w-[1308px] mx-auto">
        {/* Titel */}
        <motion.h1
          className="text-6xl font-bold mb-4"
          style={{ color: primaryText }}
          variants={itemVariants}
        >
          Vontures
        </motion.h1>

        {/* Beschreibung */}
        <motion.p
          className="text-lg mb-8 text-center max-w-2xl"
          style={{ color: secondaryText }}
          variants={itemVariants}
        >
          Welcome to Vontures – your trusted source for modern and innovative products. Discover the best products in the market and stay ahead of the trends.
        </motion.p>

        {/* Buttons */}
        <motion.div className="flex space-x-4 mb-12" variants={itemVariants}>
        <Link to="/explore-products">
          <motion.button
            className="px-6 py-3 rounded-lg transition w-full"
            style={{ backgroundColor: '#ffffff', color: buttonColor }} // White background for primary button
            whileHover={{ backgroundColor: hoverColor }}
          >
            Explore Products
          </motion.button>
        </Link>

        <Link to="/trending-products">
          <motion.button
            className="px-6 py-3 rounded-lg transition w-full"
            style={{ backgroundColor: buttonColor, color: primaryText }} // Secondary button style
            whileHover={{ backgroundColor: hoverColor }}
          >
            Trending Products
          </motion.button>
        </Link>
      </motion.div>


        {/* Info-Panel */}
        <motion.div
          className="rounded-lg w-full max-w-4xl p-4 mt-24"
          style={{ backgroundColor: cardBackground }}
          variants={itemVariants}
        >
          <h3 className="text-xl font-semibold mb-2" style={{ color: primaryText }}>
            Important Information
          </h3>
          <p className="text-md" style={{ color: secondaryText }}>
            Vontures is a platform that helps users explore a wide variety of products such as shoes, zippers, pants, shirts, and more, sourced from various marketplaces. While we aim to make product browsing as seamless as possible, please note that Vontures does not directly control the authenticity, quality, or legality of the items sold. These aspects remain the responsibility of the sellers and the platforms from which the products are sourced.
            <br /><br />
            We encourage all users to do their own research and exercise caution when making purchases. Vontures is not liable for any counterfeit, misrepresented, or unsatisfactory items. Always make informed decisions and assess products carefully before purchasing.
          </p>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Homepage;
