import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import products from './data/hoodies.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import Modal from '../Modal';

const Hoodies = () => {
  const darkBackground = '#121212';
  const cardBackground = '#1a1a1a';
  const primaryText = '#f0f0f0';
  const secondaryText = '#a1a1a1';
  const buttonColor = '#2c2c2c';
  const hoverColor = '#3a3a3a';
  const priceTagColor = '#3a3a3a';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const generateSlug = (name) => name.toLowerCase().replace(/\s+/g, '-');

  const copyToClipboard = (productTitle) => {
    const productSlug = generateSlug(productTitle);
    const fullLink = `${window.location.origin}/spreadsheets/hoodies/${productSlug}`;

    navigator.clipboard.writeText(fullLink).then(() => {
      setModalMessage('Link copied to clipboard!');
      setIsModalOpen(true);
    }).catch(err => {
      setModalMessage('Failed to copy the link.');
      setIsModalOpen(true);
      console.error('Failed to copy the link: ', err);
    });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  // Debounce loading indicator
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery) {
        setIsLoading(true);
        const timer = setTimeout(() => setIsLoading(false), 500);
        return () => clearTimeout(timer);
      } else {
        setIsLoading(false);
      }
    }, 330);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const filteredProducts = products.filter(product =>
    product.title.toLowerCase().includes(searchQuery)
  );

  const panelVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: 'easeInOut' } },
    hover: { scale: 1.02, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', transition: { duration: 0.3, ease: 'easeInOut' } },
    tap: { scale: 0.98, transition: { duration: 0.2, ease: 'easeInOut' } },
  };

  const buttonVariants = {
    hover: { scale: 1.02, backgroundColor: hoverColor, transition: { duration: 0.3, ease: 'easeInOut' } },
    tap: { scale: 0.98, transition: { duration: 0.2, ease: 'easeInOut' } },
  };

  const priceTagVariants = {
    hover: { scale: 1.02, transition: { duration: 0.3, ease: 'easeInOut' } },
  };

  return (
    <motion.section
      className="hero-section py-10 w-full px-[5%] box-border"
      style={{ backgroundColor: darkBackground }}
      initial="hidden"
      animate="visible"
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
      }}
    >
      <div className="max-w-[1308px] mx-auto py-10">
        <motion.h1 
          className="text-3xl font-bold mb-4"
          style={{ color: primaryText }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Hoodies Spreadsheet
        </motion.h1>
        <motion.p 
          className="mb-8"
          style={{ color: secondaryText }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Here you can find the best hoodies from our collection.
        </motion.p>

        {/* Search Bar */}
        <motion.div
          className="flex items-center mb-10 w-full max-w-[1308px] mx-auto"
          style={{
            backgroundColor: cardBackground,
            color: primaryText,
            borderRadius: '8px',
            border: '1px solid #2a2a2a',
            padding: '6px 12px',
          }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <FontAwesomeIcon icon={faSearch} className="mx-3 text-gray-400" />
          <input
            type="text"
            className="w-full px-4 py-1 rounded-lg focus:outline-none"
            style={{ backgroundColor: 'transparent', color: primaryText }}
            placeholder="Search hoodies..."
            value={searchQuery}
            onChange={handleSearch}
          />
          {isLoading && (
            <motion.div
              className="ml-3"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, rotate: 360 }}
              transition={{ duration: 0.6, repeat: Infinity, ease: 'linear' }}
            >
              <div className="w-4 h-4 border-2 border-t-transparent border-primaryText rounded-full animate-spin" />
            </motion.div>
          )}
        </motion.div>
      </div>

      <div className="max-w-[1308px] mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        {filteredProducts.map((product) => (
          <motion.div 
            key={product.id} 
            className="p-4 rounded-lg flex flex-col sm:flex-row"
            style={{ backgroundColor: cardBackground }}
            whileHover="hover"
            whileTap="tap"
            variants={panelVariants}
            initial="hidden"
            animate="visible"
          >
            <img 
              src={product.image} 
              alt={product.title} 
              className="w-full sm:w-48 h-48 object-cover rounded-lg mb-4 sm:mb-0 sm:mr-6"
            />
            <div className="flex flex-col justify-between flex-1">
              <div>
                <h3 className="text-lg font-bold mb-2" style={{ color: primaryText }}>{product.title}</h3>
                <p className="text-sm font-medium mb-1" style={{ color: secondaryText }}>Price tag:</p>
                <div className="flex space-x-4 mb-4">
                  <motion.span 
                    className="px-3 py-1 text-sm font-medium rounded-lg" 
                    style={{ backgroundColor: priceTagColor, color: '#ffffff' }}
                    whileHover="hover"
                    variants={priceTagVariants}
                  >
                    {product.dollarPrice}
                  </motion.span>
                  <motion.span 
                    className="px-3 py-1 text-sm font-medium rounded-lg" 
                    style={{ backgroundColor: priceTagColor, color: '#ffffff' }}
                    whileHover="hover"
                    variants={priceTagVariants}
                  >
                    {product.yenPrice}
                  </motion.span>
                </div>
              </div>
              <div className="flex justify-between items-center gap-4">
                <Link to={`/spreadsheets/hoodies/${generateSlug(product.title)}`} className="w-full">
                  <motion.button
                    className="inline-block flex justify-center items-center px-5 py-2 text-white text-sm rounded-lg transition w-full"
                    style={{ backgroundColor: buttonColor }}
                    whileHover="hover"
                    whileTap="tap"
                    variants={buttonVariants}
                  >
                    View Product
                  </motion.button>
                </Link>

                <motion.button 
                  className="inline-block px-4 py-2 text-sm rounded-lg transition w-full max-w-[50px]"
                  style={{ backgroundColor: cardBackground, color: primaryText }}
                  onClick={() => copyToClipboard(product.title)}
                  whileHover="hover"
                  whileTap="tap"
                  variants={buttonVariants}
                >
                  <FontAwesomeIcon icon={faShareAlt} />
                </motion.button>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      <div className="max-w-[1308px] mx-auto text-center mt-10">
        <p className="text-lg font-medium" style={{ color: secondaryText }}>
          A lot more products will be added in the following days, stay tuned!
        </p>
      </div>

      {isModalOpen && (
        <Modal
          message={modalMessage}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </motion.section>
  );
};

export default Hoodies;
