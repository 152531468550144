import React, { useEffect, useState } from 'react';

const Discord = () => {
  const [redirected, setRedirected] = useState(false);
  const discordLink = 'https://discord.gg/wP8fxCqcfT';

  useEffect(() => {
    // Attempt to open the Discord link in a new tab
    const newTab = window.open(discordLink, '_blank');
    if (newTab) {
      setRedirected(true);  // If a new tab opens, mark as redirected
    }
  }, []);

  const darkBackground = '#121212';
  const primaryText = '#f0f0f0';
  const secondaryText = '#a1a1a1';
  const buttonColor = '#2c2c2c';
  const hoverColor = '#3a3a3a';

  return (
    <div className="max-w-[1308px] mx-auto py-10 text-center" style={{ backgroundColor: darkBackground }}>
      <h1 className="text-3xl font-bold mb-4" style={{ color: primaryText }}>
        Proceed in the Discord App...
      </h1>
      <p className="text-lg mb-6" style={{ color: secondaryText }}>
        Thank you for joining our Discord Server 💝
      </p>

      {/* Fallback Button for manual redirection */}
      {!redirected && (
        <button
          onClick={() => window.open(discordLink, '_blank')}
          className="px-6 py-3 rounded-lg text-white text-sm"
          style={{ backgroundColor: buttonColor }}
          onMouseOver={(e) => e.target.style.backgroundColor = hoverColor}
          onMouseOut={(e) => e.target.style.backgroundColor = buttonColor}
        >
          Click here if you are not redirected automatically
        </button>
      )}
    </div>
  );
};

export default Discord;
