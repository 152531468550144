import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

const products = [
  { id: 1, name: 'Socks', description: 'Find the full spreadsheet for Socks here.', route: '/spreadsheets/socks' },
  { id: 2, name: 'Shoes', description: 'Find the full spreadsheet for Shoes here.', route: '/spreadsheets/shoes' },
  { id: 3, name: 'Pants', description: 'Find the full spreadsheet for Pants here.', route: '/spreadsheets/pants' },
  { id: 4, name: 'T-Shirts', description: 'Find the full spreadsheet for T-Shirts here.', route: '/spreadsheets/tshirts' },
  { id: 5, name: 'Zipper', description: 'Find the full spreadsheet for Zippers here.', route: '/spreadsheets/zipper' },
  { id: 6, name: 'Hoodies', description: 'Find the full spreadsheet for Hoodies here.', route: '/spreadsheets/hoodies' },
  { id: 7, name: 'Jackets', description: 'Find the full spreadsheet for Jackets here.', route: '/spreadsheets/jackets' },
  { id: 8, name: 'Hatwear', description: 'Find the full spreadsheet for Hatwear here.', route: '/spreadsheets/hatwear' },
  { id: 9, name: 'Accessories', description: 'Find the full spreadsheet for Accessories here.', route: '/spreadsheets/accessories' },
];

const ExploreProducts = () => {
  const darkBackground = '#121212';
  const cardBackground = '#1a1a1a';
  const primaryText = '#f0f0f0';
  const secondaryText = '#a1a1a1';
  const buttonColor = '#2c2c2c';
  const hoverColor = '#3a3a3a';

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: 'easeInOut', staggerChildren: 0.15 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: 'easeInOut' } },
  };

  return (
    <motion.div
      className="w-full px-[5%] box-border"
      style={{ backgroundColor: darkBackground }}
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="min-h-screen flex flex-col items-center pt-20 max-w-[1308px] mx-auto">
        {/* Title */}
        <motion.h1
          className="text-4xl font-bold mb-10 text-center"
          style={{ color: primaryText }}
          variants={itemVariants}
        >
          Explore our Spreadsheet
        </motion.h1>

        {/* 3x3 Grid */}
        <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map((product) => (
            <motion.div
              key={product.id}
              className="relative p-6 rounded-lg shadow-md transition-shadow duration-300"
              style={{ backgroundColor: cardBackground }}
              variants={itemVariants}
              whileHover={{ scale: 1.03, boxShadow: "0px 0px 10px rgba(255, 255, 255, 0.2)" }}
            >
              {/* Product Name */}
              <h2 className="text-xl font-semibold mb-4" style={{ color: primaryText }}>{product.name}</h2>

              {/* Product Description */}
              <p className="mb-4" style={{ color: secondaryText }}>{product.description}</p>

              {/* Explore Spreadsheet Button */}
              <Link to={product.route}>
                <motion.button
                  className="px-4 py-2 text-white text-sm rounded-lg transition flex items-center"
                  style={{ backgroundColor: buttonColor }}
                  whileHover={{ backgroundColor: hoverColor }}
                >
                  Explore spreadsheet now
                  <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                </motion.button>
              </Link>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ExploreProducts;
