import accessories from './data/accessories.json';
import hatwear from './data/hatwear.json';
import hoodies from './data/hoodies.json';
import jackets from './data/jackets.json';
import pants from './data/pants.json';
import shoes from './data/shoes.json';
import socks from './data/socks.json';
import tshirts from './data/tshirts.json';
import zipper from './data/zipper.json';
import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt, faArrowLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import Modal from '../Modal';

const SheetPage = () => {
  const { productName } = useParams();
  const location = useLocation();
  const [selectedAgent, setSelectedAgent] = useState('AllChinaBuy'); // Default set to AllChinaBuy
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [modals, setModals] = useState([]);

  const productCategories = {
    '/spreadsheets/accessories': accessories,
    '/spreadsheets/hatwear': hatwear,
    '/spreadsheets/hoodies': hoodies,
    '/spreadsheets/jackets': jackets,
    '/spreadsheets/pants': pants,
    '/spreadsheets/shoes': shoes,
    '/spreadsheets/socks': socks,
    '/spreadsheets/tshirts': tshirts,
    '/spreadsheets/zipper': zipper,
  };

  const categoryPath = Object.keys(productCategories).find((path) =>
    location.pathname.startsWith(path)
  );
  const categoryProducts = productCategories[categoryPath] || [];

  useEffect(() => {
    const savedAgent = localStorage.getItem('preferredAgent');
    if (savedAgent) {
      setSelectedAgent(savedAgent);
    }
  }, []);

  const product = categoryProducts.find((item) =>
    item.title && productName &&
    item.title.replace(/\s+/g, '-').toLowerCase() === productName.toLowerCase()
  );

  if (!product) {
    return (
      <motion.div
        className="max-w-[1308px] mx-auto py-10"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <h1 className="text-3xl font-bold text-gray-200 mb-4">404 Product not Found</h1>
        <p className="text-gray-400 mb-8">
          Sorry, we could not find the product you were looking for 😓
        </p>
      </motion.div>
    );
  }

  const getProductID = (link) => {
    if (link.includes('weidian.com')) {
      return link.split('itemID=')[1].split('&')[0];
    } else if (link.includes('taobao.com')) {
      return link.split('id=')[1];
    } else if (link.includes('1688.com')) {
      return link.split('offer/')[1].split('.')[0];
    }
    return null;
  };

  const productID = getProductID(product.weidianLink);

  const agents = {
    'AllChinaBuy': `https://www.allchinabuy.com/en/page/buy/?url=${encodeURIComponent(product.weidianLink)}&partnercode=EQZ3VD`, // Now at the top
    'CnFans': product.weidianLink.includes('weidian')
      ? `https://cnfans.com/de/product/?shop_type=weidian&id=${productID}&ref=1328526`
      : product.weidianLink.includes('taobao')
      ? `https://cnfans.com/de/product/?shop_type=taobao&id=${productID}&ref=1328526`
      : `https://cnfans.com/de/product/?shop_type=ali_1688&id=${productID}&ref=1328526`,
    
    'Orientdig': product.weidianLink.includes('weidian')
      ? `https://orientdig.com/product/?shop_type=weidian&id=${productID}&ref=100086950`
      : product.weidianLink.includes('taobao')
      ? `https://orientdig.com/product/?shop_type=taobao&id=${productID}&ref=100086950`
      : `https://orientdig.com/product/?shop_type=ali_1688&id=${productID}&ref=100086950`,

    'SuperBuy': `https://www.superbuy.com/en/page/buy/?url=${encodeURIComponent(product.weidianLink)}&partnercode=EQZ3pq`,
  };

  const handleAgentSelect = (agent) => {
    setSelectedAgent(agent);
    localStorage.setItem('preferredAgent', agent);
    setDropdownOpen(false);
    showModal(`Agent changed to ${agent}`);
  };

  const handleBuyNowClick = () => {
    window.open(agents[selectedAgent], '_blank');
  };

  const handleShareClick = () => {
    navigator.clipboard.writeText(window.location.href);
    showModal('Link copied to clipboard!');
  };

  const showModal = (message) => {
    setModals((prevModals) => [
      ...prevModals,
      { id: Date.now(), message },
    ]);
  };

  const closeModal = (id) => {
    setModals((prevModals) => prevModals.filter((modal) => modal.id !== id));
  };

  const pageVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeInOut' } },
  };

  const buttonVariants = {
    hover: { scale: 1.03, transition: { duration: 0.3 } },
    tap: { scale: 0.97, transition: { duration: 0.2 } }
  };

  return (
    <motion.div
      className="hero-section py-10 w-full px-[5%] box-border"
      initial="hidden"
      animate="visible"
      variants={pageVariants}
      style={{ backgroundColor: '#121212' }}
    >
      {/* Back Button */}
      <motion.div
        className="max-w-[1308px] mx-auto mb-4"
        initial={{ opacity: 0, x: -30 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.2, duration: 0.6 }}
      >
        <Link to={categoryPath} className="inline-block">
          <div className="flex items-center px-3 py-2 rounded-[6px] hover:bg-[#2c2c2c] transition" style={{ backgroundColor: '#1a1a1a' }}>
            <FontAwesomeIcon icon={faArrowLeft} className="text-gray-300 mr-2" />
            <span className="text-gray-300 font-medium">Back to {categoryPath.split('/').pop()}</span>
          </div>
        </Link>
      </motion.div>

      <motion.div
        className="max-w-[1308px] mx-auto p-6 rounded-lg shadow-lg flex flex-col sm:flex-row"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.4, duration: 0.6 }}
        style={{ backgroundColor: '#1a1a1a' }}
      >
        {/* Image on the left side */}
        <motion.img 
          src={product.image} 
          alt={product.title} 
          className="w-full sm:w-64 h-64 object-cover rounded-lg mb-4 sm:mb-0 sm:mr-6"
          initial={{ opacity: 0, x: -30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.5, duration: 0.8 }}
        />

        {/* Product details on the right side */}
        <motion.div
          className="flex flex-col justify-between flex-1"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.8 }}
        >
          <div>
            <h3 className="text-xl font-bold text-gray-200 mb-2">{product.title}</h3>
            <p className="text-sm font-medium text-gray-400 mb-1">Price:</p>
            <div className="flex space-x-4 mb-4">
              <span className="px-3 py-1 text-base font-medium text-white rounded-lg" style={{ backgroundColor: '#2c2c2c' }}>
                {product.dollarPrice}
              </span>
              <span className="px-3 py-1 text-base font-medium text-white rounded-lg" style={{ backgroundColor: '#2c2c2c' }}>
                {product.yenPrice}
              </span>
            </div>
          </div>

          {/* Buy Now Button, Agent Dropdown, and Share Button */}
          <div className="flex items-center space-x-4 mt-16">
            <motion.button
              onClick={handleBuyNowClick}
              className="flex-2 px-5 py-2 text-white text-sm rounded-lg w-[70%]"
              style={{ backgroundColor: '#2c2c2c' }}
              whileHover="hover"
              whileTap="tap"
              variants={buttonVariants}
            >
              Buy Now on {selectedAgent}
            </motion.button>

            <div className="relative flex-1 w-[30%]">
              <button 
                onClick={() => setDropdownOpen(!isDropdownOpen)}
                className="w-full px-5 py-2 text-sm rounded-lg hover:bg-[#3a3a3a] transition flex justify-between items-center"
                style={{ backgroundColor: '#2c2c2c' }}
              >
                <span className="hidden sm:block text-gray-300">Select Agent</span>
                <span className="block sm:hidden text-gray-300">Agent</span>
                <FontAwesomeIcon icon={faChevronDown} className="ml-2 text-gray-300" />
              </button>
              <AnimatePresence>
                {isDropdownOpen && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                    className="absolute mt-2 w-full rounded-lg shadow-lg z-10"
                    style={{ backgroundColor: '#2c2c2c' }}
                  >
                    {Object.keys(agents).map((agent) => (
                      <div
                        key={agent}
                        className="px-4 py-2 hover:bg-[#3a3a3a] cursor-pointer text-gray-300"
                        onClick={() => handleAgentSelect(agent)}
                      >
                        {agent}
                      </div>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <motion.button
              className="px-4 py-2 text-white text-sm rounded-lg hover:bg-[#3a3a3a] transition"
              style={{ backgroundColor: '#2c2c2c' }}
              onClick={handleShareClick}
              whileHover="hover"
              whileTap="tap"
              variants={buttonVariants}
            >
              <FontAwesomeIcon icon={faShareAlt} />
            </motion.button>
          </div>

          <p className="text-xs text-gray-500 text-left mt-2">
            Your agent preference is automatically saved and will be applied to other products.
          </p>
        </motion.div>
      </motion.div>

      {modals.map((modal, index) => (
        <Modal 
          key={modal.id}
          message={modal.message} 
          isOpen={true} 
          onClose={() => closeModal(modal.id)} 
          index={index}
        />
      ))}
    </motion.div>
  );
};

export default SheetPage;
