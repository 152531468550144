import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Modal = ({ message, isOpen, onClose, index }) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    let interval;
    if (isOpen) {
      interval = setInterval(() => {
        setProgress((prev) => (prev > 0 ? prev - 1 : 0));
      }, 30);

      setTimeout(onClose, 3000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isOpen, onClose]);

  const modalVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.4, ease: 'easeInOut' } },
    exit: { opacity: 0, y: 20, transition: { duration: 0.3, ease: 'easeInOut' } },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed right-4 bottom-4 bg-[#2c2c2c] text-gray-200 py-3 px-5 rounded-lg shadow-lg z-50 w-72"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={modalVariants}
          style={{ transform: `translateY(-${index * 80}px)` }} // Adjust stacking
        >
          <p className="text-gray-200 text-sm">{message}</p>

          {/* Countdown progress bar */}
          <div className="w-full h-1 mt-3 rounded-full bg-gray-700 overflow-hidden">
            <motion.div
              className="h-full bg-white rounded-full"  // Weißer Slider
              style={{ width: `${progress}%` }}
              initial={{ width: '100%' }}
              animate={{ width: '0%' }}
              transition={{ duration: 3, ease: 'linear' }}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
