import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Importiere React Router
import Header from './components/Header';
import Infobar from './components/InfoBar';
import Homepage from './components/Homepage'; // Verwende Homepage.js
import ExploreProducts from './components/ExploreProducts'; // Verwende ExploreProducts.js
import TrendingProducts from './components/TrendingProducts'; // Verwende TrendingProducts.js
import Discord from './components/Discord'; // Verwende Discord.js
import Socks from './components/spreadsheets/socks'; // Importiere die spezifischen Seiten
import Shoes from './components/spreadsheets/shoes';
import Pants from './components/spreadsheets/pants';
import TShirts from './components/spreadsheets/tshirts';
import Zipper from './components/spreadsheets/zipper';
import Hoodies from './components/spreadsheets/hoodies';
import Jackets from './components/spreadsheets/jackets';
import Hatwear from './components/spreadsheets/hatwear';
import Accessories from './components/spreadsheets/accessories';
import ProductPage from './components/ProductPage'; // Die neue Produktdetailseite
import Sheetpage from './components/spreadsheets/Sheetpage'; // Import the new Sheetpage component

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Infobar />
        <Routes>
          <Route path="/" element={<Homepage />} />  {/* Homepage.js */}
          <Route path="/spreadsheets/socks" element={<Socks />} />
          <Route path="/spreadsheets/shoes" element={<Shoes />} />
          <Route path="/spreadsheets/pants" element={<Pants />} />
          <Route path="/spreadsheets/tshirts" element={<TShirts />} />
          <Route path="/spreadsheets/zipper" element={<Zipper />} />
          <Route path="/spreadsheets/hoodies" element={<Hoodies />} />
          <Route path="/spreadsheets/jackets" element={<Jackets />} />
          <Route path="/spreadsheets/hatwear" element={<Hatwear />} />

          <Route path="/spreadsheets/accessories" element={<Accessories />} />
          <Route path="/explore-products" element={<ExploreProducts />} />  {/* ExploreProducts.js */}
          <Route path="/trending-products" element={<TrendingProducts />} />  {/* TrendingProducts.js */}
          <Route path="/discord" element={<Discord />} />  {/* Discord.js */}
          <Route path="/spreadsheets/accessories/:productName" element={<Sheetpage />} /> {/* Dynamic route for product pages */}
          <Route path="/spreadsheets/hatwear/:productName" element={<Sheetpage />} /> {/* Dynamic route for product pages */}
          <Route path="/spreadsheets/hoodies/:productName" element={<Sheetpage />} />
          <Route path="/spreadsheets/jackets/:productName" element={<Sheetpage />} />
          <Route path="/spreadsheets/pants/:productName" element={<Sheetpage />} />
          <Route path="/spreadsheets/shoes/:productName" element={<Sheetpage />} />
          <Route path="/spreadsheets/socks/:productName" element={<Sheetpage />} />
          <Route path="/spreadsheets/tshirts/:productName" element={<Sheetpage />} />
          <Route path="/spreadsheets/zipper/:productName" element={<Sheetpage />} />

          {/* Dynamische Route für die Produktdetailseite */}
          <Route path="/trending-products/:productname" element={<ProductPage />} /> {/* Neue Produktseite */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
