import React from 'react';
import { motion } from 'framer-motion';

const InfoBar = () => {
  const darkBlack = '#1a1a1a'; // Sanftes, dunkles Schwarz

  const barVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: 'easeInOut' } },
  };

  return (
    <motion.div
      className="w-full px-[5%] box-border"
      initial="hidden"
      animate="visible"
      variants={barVariants}
    >
      <div className="max-w-[1308px] mx-auto mt-4 py-2 bg-[#2c2c2c] rounded-lg text-center">
        <p className="text-sm text-gray-200 font-medium">
          💬 Join our Discord community{' '}
          <a
            href="https://discord.gg/rQYtnGRM"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white font-bold hover:underline"
          >
            https://discord.gg/rQYtnGRM
          </a>{' '}
          to discover even better products and get help from the community!
        </p>
      </div>
    </motion.div>
  );
};

export default InfoBar;
